import { useQuery } from '@apollo/client';
import {graphql} from 'gatsby';
import TrackDetailsContainer from 'modules/tracks/containers/TrackDetailsContainer';
import PropTypes from 'prop-types';
import React from 'react';
import SUBSCRIPTION from './subscription.graphql';

const TrackDetailsPage = (props) => {
  const { data } = useQuery(SUBSCRIPTION);
  return (
  <TrackDetailsContainer track={props.data.fugoki.track} subscription={data?.recurrentPayment?.status}/>
)};

TrackDetailsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TrackDetailsPage;

export const pageQuery = graphql`
  query trackQuery($trackId: ID!) {
    fugoki {
      track(id: $trackId) {
        id
        name
        metaTitle
        description
        metaDescription
        companiez
        level
        soon
        status
        slug
        position
        trailerYoutubeId
        backgroundImage {
          url
        }
        trackstepSet {
          edges {
            node {
              id
              position
              course {
                id
                slug
                name
                description
                status
                soon
                stageSet {
                  edges {
                    node {
                      id
                      name
                      position
                      slug
                      stepSet {
                        edges {
                          node {
                            id
                            isLocked
                            duration
                            slug
                            status
                            videotutorial {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        linkedDegree {
          id
          name
          slug
        }
      }
    }
  }
`;
