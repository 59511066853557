import notification from 'core/libs/helpers/notification';

export const getEligiblesCertificates = (id, coupon_code) =>
  id
    ? {
        types: [
          'GET_ELIGIBLE_CERTIFICATES_REQUEST',
          'GET_ELIGIBLE_CERTIFICATES_SUCCESS',
          'GET_ELIGIBLE_CERTIFICATES_FAILURE',
        ],
        config: {
          url: coupon_code
            ? `certificate-eligibility/${id}/?coupon_code=${coupon_code}`
            : `certificate-eligibility/${id}/`,
        },
        payload: {
          coupon_code,
        },
        // shouldCallAPI: state =>
        //   state.user.certificates.status !== 'fetching' &&
        //   state.user.certificates.status !== 'fetched',
      }
    : {type: 'GET_ELIGIBLE_CERTIFICATES_FAILURE'};

export const createCertificate = ({
  coupon_code,
  first_name,
  last_name,
  patch,
  track,
}) => {
  const data = new FormData();
  data.append('coupon_code', coupon_code);
  data.append('first_name', first_name);
  data.append('last_name', last_name);
  data.append('track', track);
  return {
    types: [
      'CREATE_CERTIFICATE_REQUEST',
      'CREATE_CERTIFICATE_SUCCESS',
      'CREATE_CERTIFICATE_FAILURE',
    ],
    config: {
      method: patch ? 'PATCH' : 'POST',
      url: patch ? `school/certificates/${track}/` : 'school/certificates/',
      data,
    },
    payload: {
      coupon_code,
      first_name,
      last_name,
      patch,
      track,
    },
    callback: (success, result) => {
      if (
        !success &&
        result &&
        result.response &&
        result.response.data &&
        typeof result.response.data === 'object' &&
        !result.response.data.order_no
      ) {
        Object.keys(result.response.data).forEach((item) =>
          notification({id: `error_${item}`}),
        );
      }
    },
    shouldCallAPI: (state) =>
      state.user.certificates.status !== 'fetching' &&
      state.user.certificates.status !== 'fetched',
  };
};
