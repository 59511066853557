import decodeId from 'core/libs/helpers/decodeId';
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import {graphql} from '@apollo/client/react/hoc';
import {getEligiblesCertificates} from 'modules/user/actions/certificates';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {compose, lifecycle, setPropTypes, withPropsOnChange} from 'recompose';
import TrackDetails from '../components/TrackDetails';
import SUBSCRIPTION from '../graphql/subscription.graphql';

const TrackDetailsContainer = (props) => {
  return <TrackDetails {...props} />;
};

TrackDetailsContainer.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  const {track} = ownProps;
  return {
    authenticated: state.auth.authenticated,
    coursesProgress: state.user.progress.course || {},
    trackProgress:
      (track.id &&
        state.user.progress.track &&
        state.user.progress.track[track.id]) ||
      'not started',
    stepsProgress: state.user.progress.step || {},
    stagesProgress: state.user.progress.stage || {},
    availableCertificates: state.user.certificates.availableCertificates,
    profile: state.user.profile,
  };
};

const mapDispatchToProps = {
  fetchEligiblesCertificates: getEligiblesCertificates,
};

const enhance = compose(
  graphql(SUBSCRIPTION, {
    skip: ({authenticated}) => !authenticated,
    props: ({ data }) => {
      const {error, recurrentPayment, loading} = data;
      if (loading) return {loading};
      if (error) return {error};
      const {status} = recurrentPayment ;
      return {subscription: status}
    },
  }),
  setPropTypes({
    track: PropTypes.object,
    trackSlug: PropTypes.string,
  }),
  spinnerWhileLoading(({loading}) => loading),
  withPropsOnChange(['track', 'trackSlug'], ({track, trackSlug}) => {
    const coursesDurations = track.trackstepSet.edges.map(
      ({node: {course}}) => {
        if (course.stageSet) {
          return course.stageSet.edges.reduce((sum, {node: stage}) => {
            if (stage.stepSet) {
              stage.stepSet.edges.forEach(({node: step}) => {
                sum += step.duration; // eslint-disable-line no-param-reassign
              });
            }
            return sum;
          }, 0);
        }
        return 0;
      },
    );
    return {
      trackSlug: trackSlug || track.slug,
      track: {
        ...track,
        id: decodeId(track.id),
        course_count: track.trackstepSet.edges.length,
        duration: coursesDurations.reduce((sum, courseDuration) => {
          return sum + courseDuration;
        }, 0),
        trackstepSet: undefined,
        courses:
          track.trackstepSet &&
          track.trackstepSet.edges.map(({node: {course}}, index) => ({
            ...course,
            id: decodeId(course.id),
            duration: coursesDurations[index],
            stageSet: undefined,
            stages:
              course.stageSet &&
              course.stageSet.edges.map(({node}) => ({
                ...node,
                id: decodeId(node.id),
                stepSet: undefined,
                steps:
                  node.stepSet &&
                  node.stepSet.edges.map(({node: step}) => ({
                    ...step,
                    id: decodeId(step.id),
                  })),
              })),
          })),
      },
    };
  }),
  setPropTypes({
    track: PropTypes.object.isRequired,
    trackSlug: PropTypes.string.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const {
        fetchEligiblesCertificates,
        profile: {
          data: {id},
        },
        authenticated,
      } = this.props;
      if (authenticated) {
        fetchEligiblesCertificates(id);
      }
    },
  }),
);

export default enhance(TrackDetailsContainer);
